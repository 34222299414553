.intro {
   display: flex;
   flex-direction: row;
   background-color: #e6eff2;
   height: 35rem;

   &__details {
      width: 80%;
      font-size: 1.3rem;
      line-height: 2rem;
      margin: auto 3rem;
   }
}

.illustration {
   border-radius: 0.5rem;
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   &__function {
      border-radius: 0.5rem;
      height: 25rem;
      margin: 3.5rem 0 0 0;
   }
   &__living {
      border-radius: 0.5rem;
      height: 25rem;
      margin: 6.5rem 0 0 0;
   }
}

.download {
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   margin: 5rem;
}

.download-link {
   display: flex;
   flex-direction: column;
   width: 10rem;
   .qr-code {
      height: 10rem;
   }

   .download-logo {
      margin-top: 0.5rem;
      height: 3.5rem;
   }
}

.configuration {
   font-size: 1.5rem;
   &__text {
      max-width: 50rem;
      margin: auto;
      padding: 2rem 5rem 0rem 5rem;
      line-height: 2rem;
      white-space: pre-line;
   }
}

.info-request {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
   margin: 3rem;
   font-size: 1.4rem;
   line-height: 2rem;

   @media screen and (min-width: 768px) {
      margin: 5rem;
      font-size: 1.9rem;
      line-height: 2.5rem;
   }
}

.request-margin {
   @media screen and (min-width: 768px) {
      margin-left: 3rem;
   }
}
