@import 'normalize.css';

@font-face {
   font-family: 'Nunito';
   src: url('assets/fonts/Nunito-VariableFont_wght.ttf') format('truetype');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'Exo';
   src: url('assets/fonts/Exo-VariableFont_wght.ttf') format('truetype');
   font-weight: normal;
   font-style: normal;
}

body {
   font-family: 'Exo', sans-serif;
   color: #4f4d4d;
}

h2 {
   font-size: 1.6rem;
   font-weight: 520;
   @media screen and (min-width: 768px) {
      font-size: clamp(1.9rem, 3.5vw, 3rem);
   }
}

.blur-section {
   position: absolute;
   left: 0.5rem;
   top: 57%;
   transform: translate(0, -50%);
   width: 50%;
   backdrop-filter: blur(9px);
   border-radius: 2rem;
   padding: 1rem;
   border: 0.2px solid rgba(152, 152, 152, 0.5);

   &__text {
      font-size: 3vw;
      font-weight: 350;
      line-height: 4vw;
   }

   @media screen and (min-width: 768px) {
      padding: 1.5rem;
      width: 45%;
      &__text {
         font-size: clamp(0.5rem, 2.5vw, 1.5rem);
         line-height: clamp(1.7rem, 3vw, 2.7rem);
      }
   }
}
