.brand {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   margin: auto 0 auto 1rem;
   line-height: 0.5;
   &__logo {
      width: 2.5rem;
      height: 2.5rem;
   }
   &__title {
      font-size: 1.5rem;
      margin-left: 1rem;
      font-family: Times;
      font-weight: bold;
      color: #eaedef;
   }

   &--column {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: start;
      &__title {
         margin: 1rem 0 0 0;
      }
   }

   @media screen and (min-width: 768px) {
      justify-content: left;
      padding-left: 1rem;
      margin: auto 0 auto 0;
   }
}

.burger-icon {
   display: inline-block;
   cursor: pointer;
   padding: 10px;
   margin: 0.6rem 0.6rem 0 0;
   &__line {
      display: block;
      height: 4px;
      width: 40px;
      margin-bottom: 10px;
      border-radius: 10px;
      background-color: #d4e7ef;
   }
   @media screen and (min-width: 768px) {
      display: none;
   }
}

.top-bar {
   position: fixed;

   width: 100%;
   height: 8rem;
   background: linear-gradient(
      180deg,
      rgba(79, 79, 79, 0.8) 0%,
      rgba(71, 71, 71, 0.1) 80%,
      rgba(255, 255, 255, 0) 100%
   );

   .elements {
      display: flex;
      justify-content: space-between;
      height: 4rem;
   }

   @media screen and (min-width: 768px) {
      .elements {
         justify-content: center;
      }
   }
}

.menu {
   display: flex;
   position: fixed;
   right: 0;
   top: 0;
   flex-direction: column;
   height: 101vh;
   width: 15rem;
   background-color: #18596f;

   animation: show 1.2s ease-in 1 backwards;
   @keyframes show {
      from {
         transform: translateX(100%);
      }
      to {
         transform: tranlateX(0);
      }
   }

   &__list {
      list-style: none;
      padding: 2rem 0 2rem 1rem;
      width: 100%;
      color: white;
      text-decoration: none;
      font-size: 1.2rem;
      &:hover {
         background-color: rgba(199, 199, 199, 0.1);
         cursor: pointer;
      }
   }

   @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      position: relative;
      flex-grow: 1;
      justify-content: space-evenly;
      align-items: center;
      margin: 0 3rem 0 3rem;
      background-color: transparent;
      height: 4rem;
      transform: translateX(0) !important;
      &__list {
         width: auto;
         &:hover {
            background-color: transparent;
            cursor: pointer;
         }
      }
      &__item {
         color: #eaedef;
         font-size: 1.2rem;
         font-weight: 600;
         margin: 0;
         border-radius: 1.5rem;
         padding: 0.7rem 1.2rem;
         &:hover {
            background-color: #3c596c27;
            cursor: pointer;
         }
      }
      &__icon {
         width: 1.7rem;
         height: 1.7rem;
         fill: blue;
         //margin-right: 1rem;
      }
   }
}
