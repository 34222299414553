.contact {
   padding-top: 5rem;
   &__group {
      display: flex;
      flex-direction: column;
      align-items: center;
   }
   background-color: #e6eff2;
   &__details {
      margin-top: 3rem;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 80%;
      font-size: 2rem;
      &__link {
         display: flex;
         flex-direction: column;
         justify-content: space-evenly;
         height: 25rem;
         &__img {
            height: 4rem;
            width: 4rem;
         }
      }
   }

   &__form {
      width: 100%;
      max-width: 500px;
      font-size: 1.5rem;
      margin-top: 3rem;
   }

   @media screen and (min-width: 1100px) {
      padding-top: 0;

      &__group {
         max-width: 100rem;
         flex-direction: row;
         justify-content: space-evenly;
         height: 100vh;
      }
      &__details {
         max-width: 40rem;
      }
   }
}

#message {
   height: 10rem;
}

.button__form {
   background-color: #6d90a6;
   color: #eeeeee;
   border: none;
   border-radius: 2rem;
   font-weight: 500;
   font-size: 2rem;
   transition: all 0.5s;
   text-decoration: none;
   padding: 1rem 2rem;
   margin-top: 1rem;
   cursor: pointer;

   &:hover {
      background-color: #587486;
      font-weight: 700;
      transition: all 0.5s;
   }
}

.form {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background-color: #e6eff2;

   &__group {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
   }
   &__label {
      font-size: 1.5rem;
      margin-bottom: 0.4rem;
   }
   &__input {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      &:focus {
         outline-color: #6d90a6;
      }
   }
   &__hp {
      opacity: 0;
      position: absolute;
      pointer-events: none;
   }
}
