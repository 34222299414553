.article {
   background-color: #fefefe;
   padding-top: 3rem;
   padding-bottom: 3rem;
   display: flex;
   flex-direction: column;
   align-items: center;

   &--dark {
      background-color: #e6eff2;
   }
   &__content {
      width: 70%;
      margin: 0 auto;
   }
   &__title {
      color: #3f3f3f;
   }
   &__text {
      margin-bottom: 2.5rem;
      font-size: 0.9rem;
      line-height: 1.4rem;
      white-space: pre-line;
   }
   &__illustration {
      width: clamp(2.5rem, 25vw, 17rem);
      height: clamp(2.5rem, 25vw, 17rem);
      margin: auto;
      .image {
         object-fit: fill;
         width: 100%;
         background-color: hsl(0, 0%, 92%);
         &--padding {
            padding: 2vw;
            object-fit: contain;
            height: calc(100% - 4vw);
            width: calc(100% - 4vw);
         }
      }
      &--dark {
         .image {
            background-color: #cad4d9a0;
         }
      }
   }
   &__actions {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: clamp(20rem, 80%, 100rem);
      margin-top: 3rem;
   }

   @media screen and (min-width: 768px) {
      padding: 5rem 0;
      &__description {
         display: flex;
         flex-direction: row;
         width: clamp(20rem, 80%, 100rem);
         &--dark {
            // Invert two element in a row
            flex-direction: row-reverse;
         }
      }
      &__title {
         font-size: clamp(2rem, 3vw, 2.6rem);
         line-height: 4vw;
         padding-top: 0;
      }
      &__text {
         font-size: clamp(1rem, 2vw, 1.3rem);
         line-height: clamp(1.6rem, 3vw, 2.3rem);
      }
      &__content {
         width: 50%;
      }
   }

   @media screen and (min-width: 1300px) {
      padding: 5rem 7%;
   }
}

.button {
   background-color: #6d90a6;
   color: #eeeeee;
   border: none;
   border-radius: 2rem;
   font-weight: 600;
   transition: all 0.5s;
   text-decoration: none;
   cursor: pointer;

   &:hover {
      background-color: #587486;
      font-weight: 700;
      transition: all 0.5s;
   }
   &--buy {
      &::before {
         background: url('/assets/img/icons/add-to-cart.svg') no-repeat center;
      }
   }
   &--download {
      &::before {
         background: url('/assets/img/icons/download.svg') no-repeat center;
      }
   }
   &--documentation {
      &::before {
         background: url('/assets/img/icons/manual.svg') no-repeat center;
      }
   }
   &--send {
      &::before {
         background: url('/assets/img/icons/send.svg') no-repeat center;
      }
   }
   &::before {
      content: '';
      display: block;
      background-size: contain;
      width: 20px;
      height: 20px;
      float: left;
      transition: all 0.5s;
      position: relative;
      left: -40%;
      opacity: 0;
   }

   @media screen and (min-width: 767px) {
      padding: 1rem 2.5rem 1rem calc(2.5rem - 20px);
      &:hover {
         padding: 1rem 2.5rem;
         &::before {
            display: block;
            transition: all 0.5s;
            left: -13%;
            opacity: 1;
         }
      }
   }

   @media screen and (max-width: 767px) {
      padding: 1rem 1.5rem 1rem 1.5rem;

      &__text {
         display: none;
      }

      &:hover {
         background-color: #587486;
         font-weight: 700;
         transition: all 0.5s;
      }

      &--download,
      &--documentation {
         &::before {
            left: 0%;
            opacity: 1;
         }
      }

      &--buy {
         &::before {
            display: none;
         }
      }
   }
}
