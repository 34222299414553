.images-group {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   .image {
      width: 45%;
      border-radius: 10px;
      @media screen and (min-width: 768px) {
         border-radius: 20px;
      }
      &__1 {
         margin-bottom: 2rem;
      }
      &__2 {
         margin-top: 2rem;
      }
   }
}
