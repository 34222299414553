.section {
   background-color: #fefefe;
   padding-top: 3rem;
   padding-bottom: 3rem;

   &--dark {
      background-color: #e6eff2;
   }
   &__content {
      width: 70%;
      margin: 0 auto;
   }
   &__title {
      color: #3f3f3f;
   }
   &__text {
      margin-bottom: 2.5rem;
      font-size: 0.9rem;
      line-height: 1.4rem;
      white-space: pre-line;
   }
   &__illustration {
      width: 60%;
      margin: 0 auto;
   }

   @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      padding: 5rem 0;
      &--dark {
         // Invert two element in a row
         flex-direction: row-reverse;
      }
      &__title {
         font-size: clamp(2rem, 3vw, 2.6rem);
         line-height: 4vw;
         padding-top: 0;
      }
      &__text {
         font-size: clamp(1rem, 2vw, 1.3rem);
         line-height: clamp(1.6rem, 3vw, 2.3rem);
      }
      &__illustration {
         width: 40%;
         margin: auto;
      }
      &__content {
         width: 40%;
      }
   }

   @media screen and (min-width: 1300px) {
      padding: 5rem 7%;
   }
}
