.header {
   position: relative;
   z-index: -1;
   &__background {
      width: 100%;
      //object-fit: cover;
   }
}

.title {
   color: #4c4c4c;
   text-align: center;
   font-size: 5vw;
   margin: 0.5rem 0.7rem 1rem 0.7rem;
   font-weight: 500;
   &--up {
      display: block;
      //margin-top: 2rem;
      font-size: 4vw;
      margin: 0;
      padding: 1.5rem 0 1.5rem 0;
      background-color: rgba(246, 246, 246, 0.589);
   }
   &--down {
      display: none;
   }

   @media screen and (min-width: 768px) {
      text-align: left;
      margin: 0;
      //font-size: 1rem;
      font-size: clamp(2rem, 3vw, 3rem);
      &--down {
         display: block;
      }
      &--up {
         display: none;
      }
   }
}
