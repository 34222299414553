.techno {
   margin: 5rem;
   text-align: center;
   &__group {
      margin-top: 5rem;
      &__logo {
         height: 10rem;
         margin: 2rem;
         &--small {
            height: 5rem;
         }
      }
   }
}
