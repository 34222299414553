.description {
   font-size: 1.1rem;
   &__title {
      font-size: 2.5rem;
   }
}

.form-order {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   &__label {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      margin-top: 1rem;
   }
   &__input {
      height: 2rem;
      width: 13rem;
   }
   &__hp {
      opacity: 0;
      position: absolute;
      pointer-events: none;
   }
}

.input-group {
   margin-top: 0rem;
   margin-bottom: 2rem;
}

.column {
   display: flex;
   flex-direction: column;
}

.row {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
}

.customModal {
   border-radius: 2rem;
   background: #f2f4f5 !important;
   max-width: 600px !important;
   width: 100%;
}
